import React from "react";

function Table({ list, total, meals }) {
  return (
    <>
      <div className="overflow-x-auto">
        <table className="mt-3 table-auto border-collapse border border-gray-300 w-full">
          <thead>
            <tr className="bg-gray-100 p-1 border-b border-gray-300">
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Hotel Name</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">P#</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Room</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">R#</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Ref#</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Check-In</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Check-Out</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Meal</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">M-P</th>
              <th className="font-bold border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">Price</th>
              <th className="font-bold p-2 text-xs sm:text-sm md:text-base lg:text-lg">Amount(SR)</th>
            </tr>
          </thead>
          <tbody>
            {list.map(({ id, description, pno, room, rnum, ref, checkin, checkout, quantity, price, amount }) => (
              <tr key={id} className="border-b border-gray-300">
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{description}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{pno}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{room}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{rnum}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{ref}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{checkin}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{checkout}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{meals}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{quantity}</td>
                <td className="border-r border-gray-300 p-2 text-xs sm:text-sm md:text-base lg:text-lg">{price}</td>
                <td className="p-2 text-xs sm:text-sm md:text-base lg:text-lg">{amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <h2 className="flex items-end justify-end text-gray-800 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold">
          Total (SR) = {total.toLocaleString()}
        </h2>
      </div>
    </>
  );
}

export default Table;
