import React, { useState, useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { FaEdit } from "react-icons/fa";

function TableForm({
  description,
  quantity,
  price,
  amount,
  setDescription,
  setPrice,
  setAmount,
  setQuantity,
  list,
  setList,
  total,
  setTotal,
  hotelname,
  setHotelName,
  pno,
  setPnumber,
  room,
  setRoom,
  rnum,
  setRoomnumber,
  refNumber,
  setRefNumber,
  checkin,
  setCheckIn,
  checkout,
  setCheckOut,
  meals,
  setMeals,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!description || !quantity || !price) {
      alert("Please fill in all inputs");
    } else {
      const newItems = {
        id: uuidv4(),
        description,
        pno,
        room,
        rnum,
        ref: refNumber,
        checkin,
        checkout,
        quantity,
        price,
        amount,
        meals,
      };
      setDescription("");
      setQuantity("");
      setPrice("");
      setAmount("");
      setHotelName("");
      setPnumber("");
      setRoom("");
      setRoomnumber("");
      setRefNumber("");
      setCheckIn("");
      setCheckOut("");
      setList([...list, newItems]);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const calculateAmount = () => {
      setAmount(parseFloat(quantity) + parseFloat(price));
    };
    calculateAmount();
  }, [quantity, price, setAmount]);

  useEffect(() => {
    const calculateTotal = () => {
      const sum = list.reduce((acc, item) => acc + item.amount, 0);
      setTotal(sum);
    };
    calculateTotal();
  }, [list, setTotal]);

  const handleEdit = (id) => {
    const editableItem = list.find((item) => item.id === id);
    setList(list.filter((item) => item.id !== id));
    setIsEditing(true);
    setDescription(editableItem.description);
    setQuantity(editableItem.quantity);
    setPrice(editableItem.price);
    setAmount(editableItem.amount);
    setHotelName(editableItem.hotelname);
    setPnumber(editableItem.pno);
    setRoom(editableItem.room);
    setRoomnumber(editableItem.rnum);
    setRefNumber(editableItem.ref);
    setCheckIn(editableItem.checkin);
    setCheckOut(editableItem.checkout);
  };

  const handleDelete = (id) => {
    setList(list.filter((item) => item.id !== id));
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="grid gap-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* <div className="flex flex-col">
            <label htmlFor="hotelname" className="text-sm font-medium">Hotel Name</label>
            <input
              type="text"
              name="hotelname"
              id="hotelname"
              placeholder="Hotel Name"
              value={hotelname}
              onChange={(e) => setHotelName(e.target.value)}
              className="p-2 border rounded"
            />
          </div> */}
          <div className="flex flex-col">
            <label htmlFor="pno" className="text-sm font-medium">Personal Number</label>
            <input
              type="text"
              name="pno"
              id="pno"
              placeholder="Personal Number"
              value={pno}
              onChange={(e) => setPnumber(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="room" className="text-sm font-medium">Room Type</label>
            <input
              type="text"
              name="room"
              id="room"
              placeholder="Room Type"
              value={room}
              onChange={(e) => setRoom(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="rnum" className="text-sm font-medium">Room Number</label>
            <input
              type="text"
              name="rnum"
              id="rnum"
              placeholder="Room Number"
              value={rnum}
              onChange={(e) => setRoomnumber(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="refNumber" className="text-sm font-medium">Reference Number</label>
            <input
              type="text"
              name="refNumber"
              id="refNumber"
              placeholder="Reference Number"
              value={refNumber}
              onChange={(e) => setRefNumber(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="checkin" className="text-sm font-medium">Check-In Date</label>
            <input
              type="date"
              name="checkin"
              id="checkin"
              placeholder="Check-In Date"
              value={checkin}
              onChange={(e) => setCheckIn(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="checkout" className="text-sm font-medium">Check-Out Date</label>
            <input
              type="date"
              name="checkout"
              id="checkout"
              placeholder="Check-Out Date"
              value={checkout}
              onChange={(e) => setCheckOut(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="meals" className="text-sm font-medium">Meal</label>
            <input
              type="text"
              name="meals"
              id="meals"
              placeholder="Add Meal"
              value={meals}
              onChange={(e) => setMeals(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="description" className="text-sm font-medium">Hotel Name</label>
            <input
              type="text"
              name="description"
              id="description"
              placeholder="Hotel Name"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="quantity" className="text-sm font-medium">M-P</label>
            <input
              type="number"
              name="quantity"
              id="quantity"
              placeholder="Add Meal Price"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="price" className="text-sm font-medium">Price</label>
            <input
              type="number"
              name="price"
              id="price"
              placeholder="Price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="amount" className="text-sm font-medium">Amount</label>
            <p className="p-2 border rounded bg-gray-100">{amount}</p>
          </div>
        </div>
        <button
          type="submit"
          className="mt-5 bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
        >
          {isEditing ? "Edit Item" : "Add Item"}
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 mt-6">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hotelname</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">P#</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">R#</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ref#</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Checkin</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Checkout</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Meal</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">M-P</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Edit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Delete</th>
            </tr>
          </thead>
          {list.map(({ id, description, quantity, price, amount, pno, room, rnum, ref, checkin, checkout, meals }) => (
            <tbody key={id} className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap">{description}</td>
                <td className="px-6 py-4 whitespace-nowrap">{pno}</td>
                <td className="px-6 py-4 whitespace-nowrap">{room}</td>
                <td className="px-6 py-4 whitespace-nowrap">{rnum}</td>
                <td className="px-6 py-4 whitespace-nowrap">{ref}</td>
                <td className="px-6 py-4 whitespace-nowrap">{checkin}</td>
                <td className="px-6 py-4 whitespace-nowrap">{checkout}</td>
                <td className="px-6 py-4 whitespace-nowrap">{meals}</td>
                <td className="px-6 py-4 whitespace-nowrap">{quantity}</td>
                <td className="px-6 py-4 whitespace-nowrap">{price}</td>
                <td className="px-6 py-4 whitespace-nowrap">{amount}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button onClick={() => handleEdit(id)}>
                    <FaEdit className="text-blue-500" />
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button onClick={() => handleDelete(id)}>
                    <MdDeleteOutline className="text-red-500" />
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      <div>
        <h2 className="flex items-end justify-end text-gray-800 text-4xl font-bold mt-6">
          Rs. {total.toLocaleString()}
        </h2>
      </div>
    </>
  );
}

export default TableForm;
