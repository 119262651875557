import React, { useState, useRef } from "react";
import MainDetails from "./components/MainDetails";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Notes from "./components/Notes";
import Table from "./components/Table";
import TableForm from "./components/TableForm";
import ReactToPrint from "react-to-print";
import InvoiceDetails from "./components/InvoiceDetails";
function App() {
  const [showInvoice, setShowInvoice] = useState(true);
  const [hname, setHName] = useState("Ny International");
  const [address, setAddress] = useState("Saudia");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  const [hotelname, setHotelName] = useState("");
  const [pno, setPnumber] = useState("");
  const [room, setRoom] = useState("");
  const [rnum, setRoomnumber] = useState("");
  const [ref, SetRefNumber] = useState("");
  const [checkin, setCheckIn] = useState("");
  const [checkout, setCheckOut] = useState("");
  const [meals, setMeals] = useState("");
  // const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [client,setClient] =useState("")
  const [date,setDate] = useState("")
  const [invoicenum,setInvoicenum] = useState("")
  const [status, setStatus] = useState("Confirmed");
  const [care, setCareof] = useState("");
  const componentRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  return (
    <main className="m-5 p-5 md:max-w-xl md:mx-auto lg:max-w-2xl xl:max-w-4xl bg-white rounded shadow">
      <ReactToPrint
        trigger={() => (
          <button
            className="bg-blue-500 mb-5 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 
              hover:bg-transparent hover:text-blue-500 transition-all duration-300"
          >
            Print/Download
          </button>
        )}
        content={() => componentRef.current}
      />
      {showInvoice ? (
        <>
          <div ref={componentRef} className="p-5">
            <Header handlePrint={handlePrint} status={status} setStatus={setStatus} />
            <MainDetails name={hname} address={address} />
            <InvoiceDetails date={date} invoicenum={invoicenum} client={client} care={care} />
            {/* <ClientDetails client ={client} />
            <Dates invoicenum = {invoicenum} date ={date} /> */}
            <Table
              description={description}
              quantity={quantity}
              price={price}
              amount={amount}
              list={list}
              setList={setList}
              total={total}
              setTotal={setTotal}
              pno={pno}
              setPnumber={setPnumber}
              room={room}
              setRoom={setRoom}
              rnum={rnum}
              setRoomnumber={setRoomnumber}
              refNumber={ref}
              setRefNumber={SetRefNumber}
              checkin={checkin}
              setCheckIn={setCheckIn}
              checkout={checkout}
              setCheckOut={setCheckOut}
              meals={meals }
              setMeals={setMeals}
             
            />
            <Notes />
            <Footer name={hname} address={address} />
          </div>
          <button
            onClick={() => setShowInvoice(false)}
            className="mt-5 bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
          >
            Edit Information
          </button>
        </>
      ) : (
        <div className="flex flex-col justify-center">
          <article className="md:grid grid-cols-2 gap-10">
            <div className="flex flex-col">
              <label htmlFor="name">Enter Your Hotel-Name</label>
              <input
                type="text"
                name="hname"
                id="hname"
                placeholder=" Hotel Name"
                autoComplete="off"
                value={hname}
                onChange={(e) => setHName(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="status">Invoice Status</label>
              <select
                name="status"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="bg-white border border-gray-300 rounded p-2"
              >
                <option value="Confirmed">Confirmed</option>
                <option value="Tentative">Tentative</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label htmlFor="address">Enter your address</label>
              <input
                type="text"
                name="text"
                id="address"
                placeholder="Enter Your address"
                autoComplete="off"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="address">Enter Invoice Number</label>
              <input
                type="text"
                name="invoicenum"
                id="invoicenum"
                placeholder="Enter Invoice Number"
                autoComplete="off"
                value={invoicenum}
                onChange={(e) => setInvoicenum(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="address">Care of</label>
              <input
                type="text"
                name="care"
                id="care"
                placeholder="Care of"
                autoComplete="off"
                value={care}
                onChange={(e) => setCareof(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="address">Enter Date</label>
              <input
                type="date"
                name="date"
                id="date"
                placeholder="Enter Date"
                autoComplete="off"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="address">Client Name </label>
              <input
                type="text"
                name="client"
                id="client"
                placeholder="Enter Client Name"
                autoComplete="off"
                value={client}
                onChange={(e) => setClient(e.target.value)}
              />
            </div>
          </article>
          {/* <label htmlFor="email">Enter your Email</label>
          <input
            type="text"
            name="text"
            id="email"
            placeholder="Enter Your email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          /> */}
          {/* <label htmlFor="website">Enter your Website</label>
          <input
            type="text"
            name="text"
            id="website"
            placeholder="Enter Your website"
            autoComplete="off"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          /> */}
          {/* <label htmlFor="phone">Enter your phone</label>
          <input
            type="text"
            name="text"
            id="phone"
            placeholder="Enter Your phone"
            autoComplete="off"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          /> */}
          <TableForm
            description={description}
            setDescription={setDescription}
            quantity={quantity}
            setQuantity={setQuantity}
            price={price}
            setPrice={setPrice}
            amount={amount}
            setAmount={setAmount}
            list={list}
            setList={setList}
            total={total}
            setTotal={setTotal}
            hotelname={hotelname}
            setHotelName={setHotelName}
            pno={pno}
            setPnumber={setPnumber}
            room={room}
            setRoom={setRoom}
            rnum={rnum}
            setRoomnumber={setRoomnumber}
            refNumber={ref}
            setRefNumber={SetRefNumber}
            checkin={checkin}
            setCheckIn={setCheckIn}
            checkout={checkout}
            setCheckOut={setCheckOut}
            meals={meals}
            setMeals={setMeals}
          />
         
          <button
            onClick={() => setShowInvoice(true)}
            className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300"
          >
            Preview Invoice
          </button>
        </div>
      )}
    </main>
  );
}

export default App;
