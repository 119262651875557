import React from 'react';

function Dates({ date, invoicenum }) {
  return (
    <section className="flex flex-col">
      <h2 className="text-lg font-bold">Date: {date}</h2>
      <h2 className="text-lg font-bold">Invoice #: {invoicenum}</h2>
    </section>
  );
}

export default Dates;
