import React from 'react';

function MainDetails({ name, address }) {
  return (
    <>
      <section className="flex flex-col justify-end">
        <h2 className='font-bold text-xl uppercase md:text-2xl'>{name}</h2>
        <p className='text-lg text-red-700'>{address}</p>
      </section>
    </>
  );
}

export default MainDetails;
