import React from "react";

const Footer = ({name,address,email,website}) => {
  return (
    <>

<div className="border border-black rounded-md p-4">
      <h1 className="lg:w-1/2 text-justify font-bold text-red-500 p-2">Important Information</h1>
      <div className="border-t border-black p-4">
        <h3>
          1: All BOOKINGS ARE NON-REFUNDABLE<br />
          2: CHECK IN TIME 4:PM <br />
          3: CHECK OUT TIME 12:PM<br />
          4: IF WE DO NOT RECEIVE THE PAYMENT BEFORE OPTION DATE THE RESERVATION WILL BE CANCEL WITHOUT ANY APPROVAL FROM YOUR SIDE
        </h3>
      </div>
    </div>
      {/* <footer className="footer border-t-2 border-gray-300 pt-5">
        <ul className="flex flex-wrap items-center justify-center">
          <li>
            <span className="font-bold">{name}</span>
          </li>
          <li>
            <span className="font-bold">{address}</span>
          </li>
          <li>
            <span className="font-bold">{email}</span>
          </li>
          <li>
            <span className="font-bold">{website}</span>
          </li>
          <li>
            <span className="font-bold">Account Holder</span>
          </li>
          <li>
            <span className="font-bold">Account Number</span>
          </li>
          <li>
            <span className="font-bold">Website</span>
          </li>
        </ul>
      </footer> */}
    </>
  );
};

export default Footer;
