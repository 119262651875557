import React from "react";

function Header({ handlePrint, status }) {
  const getStatusImage = () => {
    if (status === "Confirmed") {
      return "/confirm.png"; // Update with the actual path to your confirmed stamp image
    } else if (status === "Tentative") {
      return "/tent.png"; // Update with the actual path to your tentative stamp image
    }
    return null;
  };

  return (
    <>
      <header className="flex flex-row items-center justify-between mb-5 w-full">
        <div className="flex items-center">
          <img src="/logo.png" alt="Logo" className="h-16 w-auto mb-3" />
        </div>
        {getStatusImage() && (
          <div className="flex items-center">
            <img
              src={getStatusImage()}
              alt={status}
              className="h-20 w-auto mb-3 ml-4" // Adjust height and width as needed
            />
          </div>
        )}
        {/* Uncomment this block if needed for print, download, and send buttons
        <div className="flex items-center justify-between mt-3 xl:mt-0">
          <button
            onClick={handlePrint}
            className="bg-gray-500 text-white font-bold py-2 px-8 rounded shadow border-2 
            border-gray-500 hover:bg-transparent hover:text-gray-500 transition-all duration-300"
          >
            Print
          </button>
          <button className="bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 
          hover:bg-transparent hover:text-blue-500 transition-all duration-300 mx-2">
            Download
          </button>
          <button className="bg-green-500 text-white font-bold py-2 px-8 rounded shadow border-2 
          border-green-500 hover:bg-transparent hover:text-green-500 transition-all duration-300">
            Send
          </button>
        </div>
        */}
      </header>
    </>
  );
}

export default Header;
