import React from 'react';

function ClientDetails({ client, care }) {
  return (
    <>
      <section className='mt-5'>
        <h2 className='text-lg font-bold uppercase'>Customer Name: {client}</h2>
        <h2 className='text-lg font-bold uppercase'>C/O: {care}</h2>
      </section>
    </>
  );
}

export default ClientDetails;
