import React from 'react';
import Dates from './Dates'; // Ensure this path is correct
import ClientDetails from './ClientDetails'; // Ensure this path is correct

function InvoiceDetails({ date, invoicenum, client, care }) {
  return (
    <div className="mt-3 flex flex-col md:flex-row justify-between items-start space-y-4 md:space-y-0 md:space-x-4">
      <Dates date={date} invoicenum={invoicenum} />
      <ClientDetails client={client} care={care} />
    </div>
  );
}

export default InvoiceDetails;
