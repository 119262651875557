import React from 'react';

function Notes() {
  return (
    <>
      {/* Notes */}
      <section className="mt-10 mb-5 border border-black rounded-md">
        <h1 className="lg:w-1/2 text-justify font-bold text-red-500 p-2">Notes to the Client...</h1>
        <p className="border-t border-black p-4">
          The usual check-in time is 4:00 PM hours. Rooms may not be available for early check-ins unless specifically required in advance. However, luggage may be deposited at the hotel reception and collected once the room is allotted. - Note that reservations   
 may be canceled automatically after 18:00 hours if hotels are not informed about the approximate time of late arrivals.
        </p>
      </section>
    </>
  );
}

export default Notes;